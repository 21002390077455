import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { getContactUsAsync } from "../redux";
import {
  Card,
  CardBody,
  CardHeader,
} from "./../../../../_metronic/_partials/controls";

const ViewContactUs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ContactList } = useSelector((state) => state.contactUs);
  const { id } = useParams();
  let { cdnURL } = useSelector((state) => state.environnment.environmentLists);

  useEffect(() => {
    dispatch(getContactUsAsync(id));
    // eslint-disable-next-line
  }, []);

  const redirectBack = () => {
    history.push("/contact-us");
  };

  return (
    <>
      <Formik enableReinitialize initialValues={ContactList}>
        {({ values }) => (
          <Row className="justify-content-center">
            <Col lg={11}>
              <Card>
                <CardHeader title="View Contact Us"></CardHeader>
                <CardBody>
                  <Form>
                    <div className="form-group">
                      <label className="form-label"> Name</label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="Name"
                        type="text"
                        disabled={true}
                        name="name"
                        value={values.name}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Email</label>
                      <Link
                        href="#"
                        onClick={(e) => {
                          window.location = `mailto:${values.email}`;
                          e.preventDefault();
                        }}
                      >
                        <input
                          className="form-control re_inputRouded"
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            backgroundColor: "#F3F6F9",
                            opacity: 1,
                          }}
                          placeholder="Email"
                          type="text"
                          disabled={true}
                          name="emails"
                          value={values.email}
                        />
                      </Link>
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Contact No.</label>
                      <Link
                        href="#"
                        onClick={(e) => {
                          window.location = `tel:${values.countryCode}${values.contactNumber}`;
                          e.preventDefault();
                        }}
                      >
                        <input
                          className="form-control re_inputRouded"
                          style={{ cursor: "pointer", color: "blue" }}
                          placeholder="Contact Number"
                          type="text"
                          disabled={true}
                          name="contactNumber"
                          value={`${values.contactNumber}`}
                        />
                      </Link>
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Country From IP</label>
                      <input
                        className="form-control re_inputRouded"
                        style={{ cursor: "pointer", color: "blue" }}
                        placeholder="Country From IP"
                        type="text"
                        disabled={true}
                        name="countryName"
                        value={values.countryName}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Country From Phone Ext
                      </label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="Country From Phone Ext"
                        type="text"
                        disabled={true}
                        name="countryNameFromCountryCode"
                        value={values.countryNameFromCountryCode}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Skype Id</label>
                      <Link
                        href="#"
                        onClick={(e) => {
                          window.location = `skype:${values.skypeId}`;
                          e.preventDefault();
                        }}
                      >
                        <input
                          className="form-control re_inputRouded"
                          style={{ cursor: "pointer", color: "blue" }}
                          placeholder="Skype Id"
                          type="text"
                          disabled={true}
                          name="skypeId"
                          value={values.skypeId}
                        />
                      </Link>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Project Requirements</label>
                      <textarea
                        className="form-control re_inputRouded"
                        rows={5}
                        placeholder="Project Requirement"
                        type="text"
                        disabled={true}
                        name="pro_requirement"
                        value={values.pro_requirements}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label"> Page Name</label>
                      <input
                        className="form-control re_inputRouded"
                        placeholder="PageName"
                        type="text"
                        disabled={true}
                        name="pagename"
                        value={values.pageName}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label"> File</label>
                      <Link
                        href="#"
                        onClick={(e) => {
                          if (values.uploadFile) {
                            window.open(
                              `${cdnURL}${values.uploadFile}`,
                              "_blank"
                            );
                            e.preventDefault();
                          }
                        }}
                      >
                        <input
                          className="form-control re_inputRouded"
                          style={{ cursor: "pointer", color: "blue" }}
                          placeholder="uploadFile"
                          type="text"
                          name="uploadFile"
                          value={values.uploadFile}
                        />
                      </Link>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="blue"
                        className="px-5 defpddng "
                        size="lg"
                        onClick={redirectBack}
                      >
                        Close
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default ViewContactUs;
