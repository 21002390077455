import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import BasicPagination from "../../../pagination/BasicPagination";
import { useIsAccessAllow } from "../../../users/redux";
import { HfdiBlogActions, deleteBlogAsync, getAllBlogAsync } from "../../redux";

const HfdiBlogTable = () => {
  const history = useHistory();
  const {
    isLoading,
    blogList,
    skip,
    limit,
    searchBy,
    searchText,
    status,
  } = useSelector((state) => state.blog, shallowEqual);

  useEffect(() => {
    dispatch(getAllBlogAsync(searchBy, searchText, status));
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(HfdiBlogActions.setBlogBatchNumber(count));
  };

  const onEditClick = (data) => {
    history.push(`/hfdi_blog/edit-blog/${data?._id}`);
  };
  const onDeleteClick = (data) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteBlogAsync(data?._id));
      dispatch(getAllBlogAsync(searchBy, searchText, status));
    }
  };
  const statusOptionList = {
    draft: "draft",
    published: "Published",
    pending_review: "Pending Review",
    future: "Future",
    auto_draft: "Auto Draft",
  };
  const getStatuslabel = (status) => {
    if (status && statusOptionList[status]) {
      return statusOptionList[status];
    }
    return status;
  };
  const frontend_website_url =
    process.env.REACT_APP_HIRE_FULL_STACK_DEV_INDIA_FRONTEND_WEBSITE_URL || "";

  const isDeleteAllow = useIsAccessAllow("hfdi_blog.delete_blog");
  const isEditBlogAllow = useIsAccessAllow("hfdi_blog.edit_blog");

  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Slug</th>
                  <th>Title</th>
                  <th>Tags</th>
                  <th>Categories</th>
                  <th>Created On</th>
                  <th>Status</th>
                  <th width="140">Action</th>
                </tr>
              </thead>
              <tbody>
                {blogList.records && blogList.records.length ? (
                  blogList.records.map((blogLists, index) => (
                    <tr key={index}>
                      <td>
                        <span>{skip * limit + (index + 1)}</span>
                      </td>
                      <td>
                        <span>{blogLists.slug}</span>
                      </td>
                      <td>
                        <span>{blogLists.title}</span>
                      </td>
                      <td>
                        <span>
                          {blogLists.tags ? blogLists.tags.join(", ") : ""}
                        </span>
                      </td>
                      <td>
                        <span>
                          {blogLists.categories
                            ? blogLists.categories.join(", ")
                            : ""}
                        </span>
                      </td>
                      <td>
                        <span>
                          {new Date(blogLists.createdOn).toLocaleString()}
                        </span>
                      </td>
                      <td>
                        <span style={{ textTransform: "capitalize" }}>
                          {getStatuslabel(blogLists.status)}
                        </span>
                      </td>
                      <td>
                        <a
                          href={`${frontend_website_url}/${blogLists.slug}?livePreview=true`}
                          rel="noreferrer"
                          target="_blank"
                          title="Preview"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/custom/eye.svg"
                              )}
                            />
                          </span>
                        </a>

                        {isEditBlogAllow && (
                          <button
                            title="Edit"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                            onClick={() => onEditClick(blogLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}

                        {isDeleteAllow && (
                          <button
                            title="Delete"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => onDeleteClick(blogLists)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              />
                            </span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={blogList.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {blogList.recordsTotal} Records
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HfdiBlogTable;
