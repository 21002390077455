import React, { useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getAllAuthorAsync, AuthorManagementActions } from "../redux";
import AuthorManagementTable from "./authorManagementDetail/authorManagementTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIsAccessAllow } from "../../users/redux";

export function HfdiAuthor() {
  const dispatch = useDispatch();
  const searchAuthorRef = useRef();
  const history = useHistory();
  const type = "AUTHOR";
  const { refreshAuthorList, searchBy, searchText } = useSelector(
    (state) => state.author,
    shallowEqual
  );
  useEffect(() => {
    dispatch(AuthorManagementActions.setSelectedAuthor(null));
    if (searchAuthorRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(AuthorManagementActions.refreshAuthorList());
        }
      };
      let input = searchAuthorRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(AuthorManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (refreshAuthorList) {
      dispatch(getAllAuthorAsync(searchBy, searchText, type));
    }
    dispatch(getAllAuthorAsync(searchBy, searchText, type));
  }, [dispatch, refreshAuthorList, searchBy, searchText]);

  // useEffect(() => {
  //   dispatch(getAllAuthorTemplatesAsync());
  // }, [dispatch]);

  const onAddClick = () => {
    history.push("/hfdi_author/add-author");
  };

  const onSearchTextChange = (e) => {
    dispatch(AuthorManagementActions.searchTextChange(e.target.value));
  };
  const isAddAuthorAllow = useIsAccessAllow("hfdi_author.add_author");
  const isSearchAllow = useIsAccessAllow("hfdi_author.list");
  return (
    <>
      <Card>
        <CardHeader title="Author Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  {isAddAuthorAllow && (
                    <div className="form-group mr-5 mb-1 mt-2">
                      <button
                        className="btn_new btn-sm"
                        type="button"
                        onClick={() => onAddClick()}
                      >
                        Add
                      </button>
                    </div>
                  )}
                  {isSearchAllow && (
                    <div className="form-group mr-3 mb-1">
                      <input
                        name="Search"
                        placeholder="Search. . ."
                        type="text"
                        className="form-control"
                        value={searchText}
                        onChange={onSearchTextChange}
                        ref={searchAuthorRef}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <AuthorManagementTable />
      </Card>
    </>
  );
}
