import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import { userAsync } from "../redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Select from "react-select";
import { useParams, Redirect, Link, useHistory } from "react-router-dom";
import { regexValidation } from "../../../../_metronic/_helpers/RegexValidationHelpers";
import { CircularProgress } from "@material-ui/core";
import {
  getRolePermissionAsync,
  getuserDetailAsync,
  updateUser,
} from "../redux/userApi";
import Checkbox from "../../../components/Checkbox";
// import { getCountryDailCodeAsync } from "../../countryManagement/redux/countryManagementApi";

export const RolePermissions = [
  {
    label: "User Management",
    key: "user",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Delete",
        key: "delete",
      },
      {
        label: "Add user",
        key: "add_user",
      },
      {
        label: "Edit user",
        key: "edit_user",
      },
    ],
  },
  {
    label: "Contact us",
    key: "contact_us",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "View",
        key: "view",
      },
      {
        label: "Delete",
        key: "delete",
      },
      {
        label: "Multiple delete",
        key: "multiple_delete",
      },
    ],
  },
  {
    label: "Get A Quote",
    key: "get_a_quote",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "View",
        key: "view",
      },
      {
        label: "Delete",
        key: "delete",
      },
      {
        label: "Multiple delete",
        key: "multiple_delete",
      },
      {
        label: "Hire AI Get Quote",
        key: "hire_ai_get_quote",
      },
    ],
  },
  {
    label: "Careers",
    key: "careers",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "View",
        key: "view",
      },
      {
        label: "Delete",
        key: "delete",
      },
    ],
  },
  {
    label: "Blog",
    key: "blog",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add blog",
        key: "add_blog",
      },
      {
        label: "Delete blog",
        key: "delete_blog",
      },
      {
        label: "Edit blog",
        key: "edit_blog",
      },
    ],
  },
  {
    label: "Tag",
    key: "tag",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add tag",
        key: "add_tag",
      },
      {
        label: "Delete tag",
        key: "delete_tag",
      },
      {
        label: "Edit tag",
        key: "edit_tag",
      },
    ],
  },
  {
    label: "Category",
    key: "category",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add category",
        key: "add_category",
      },
      {
        label: "Delete category",
        key: "delete_category",
      },
      {
        label: "Edit category",
        key: "edit_category",
      },
    ],
  },
  {
    label: "Email management",
    key: "email_management",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add template",
        key: "add_template",
      },
      {
        label: "Delete template",
        key: "delete_template",
      },
      {
        label: "Edit template",
        key: "edit_template",
      },
    ],
  },
  {
    label: "Mail subscribers",
    key: "mail_subscribers",
    child: [
      {
        label: "List",
        key: "list",
      },
    ],
  },
  {
    label: "Dubai Blog",
    key: "dubai_blog",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add blog",
        key: "add_blog",
      },
      {
        label: "Delete blog",
        key: "delete_blog",
      },
      {
        label: "Edit blog",
        key: "edit_blog",
      },
    ],
  },
  {
    label: "Dubai Tag",
    key: "dubai_tag",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add tag",
        key: "add_tag",
      },
      {
        label: "Delete tag",
        key: "delete_tag",
      },
      {
        label: "Edit tag",
        key: "edit_tag",
      },
    ],
  },
  {
    label: "Dubai Category",
    key: "dubai_category",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add category",
        key: "add_category",
      },
      {
        label: "Delete category",
        key: "delete_category",
      },
      {
        label: "Edit category",
        key: "edit_category",
      },
    ],
  },
  {
    label: "Dubai Author",
    key: "dubai_author",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add Author",
        key: "add_author",
      },
      {
        label: "Delete Author",
        key: "delete_author",
      },
      {
        label: "Edit Author",
        key: "edit_author",
      },
    ],
  },
  {
    label: "Hire AI Blog",
    key: "hire_ai_blog",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add blog",
        key: "add_blog",
      },
      {
        label: "Delete blog",
        key: "delete_blog",
      },
      {
        label: "Edit blog",
        key: "edit_blog",
      },
    ],
  },
  {
    label: "Hire AI Tag",
    key: "hire_ai_tag",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add tag",
        key: "add_tag",
      },
      {
        label: "Delete tag",
        key: "delete_tag",
      },
      {
        label: "Edit tag",
        key: "edit_tag",
      },
    ],
  },
  {
    label: "Hire AI Category",
    key: "hire_ai_category",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add category",
        key: "add_category",
      },
      {
        label: "Delete category",
        key: "delete_category",
      },
      {
        label: "Edit category",
        key: "edit_category",
      },
    ],
  },
  {
    label: "Hire AI Author",
    key: "hire_ai_author",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add author",
        key: "add_author",
      },
      {
        label: "Delete author",
        key: "delete_author",
      },
      {
        label: "Edit author",
        key: "edit_author",
      },
    ],
  },
  {
    label: "HFDI Blog",
    key: "hfdi_blog",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add blog",
        key: "add_blog",
      },
      {
        label: "Delete blog",
        key: "delete_blog",
      },
      {
        label: "Edit blog",
        key: "edit_blog",
      },
    ],
  },
  {
    label: "HFDI Tag",
    key: "hfdi_tag",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add tag",
        key: "add_tag",
      },
      {
        label: "Delete tag",
        key: "delete_tag",
      },
      {
        label: "Edit tag",
        key: "edit_tag",
      },
    ],
  },
  {
    label: "HFDI Category",
    key: "hfdi_category",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add category",
        key: "add_category",
      },
      {
        label: "Delete category",
        key: "delete_category",
      },
      {
        label: "Edit category",
        key: "edit_category",
      },
    ],
  },
  {
    label: "HFDI Author",
    key: "hfdi_author",
    child: [
      {
        label: "List",
        key: "list",
      },
      {
        label: "Add author",
        key: "add_author",
      },
      {
        label: "Delete author",
        key: "delete_author",
      },
      {
        label: "Edit author",
        key: "edit_author",
      },
    ],
  },
];

const UpdateUser = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // let [callingCode, setCallingCode] = useState([]);
  const { selectedSubUser, isSubUserLoading } = useSelector(
    (state) => state.user,
    shallowEqual
  );

  const listStyleClass = {
    listStyle: "none",
  };
  const [checkValue, setCheckValue] = useState(false);
  const [checkIdsarray, setCheckIdsarray] = useState([]);
  // const { dailCode } = useSelector((state) => state.countryManagement, shallowEqual);
  // const { environmentLists } = useSelector((state) => state.environnment);
  useEffect(() => {
    dispatch(getRolePermissionAsync);
    // dispatch(getCountryDailCodeAsync());
    if (!params?.id) {
      setFile("");
    }
    if (params?.id) {
      dispatch(getuserDetailAsync(params.id));
    }
  }, []);

  useEffect(() => {
    if (params?.id) {
      if (selectedSubUser.permissions) {
        setCheckIdsarray(selectedSubUser?.permissions);
      }
    }
  }, [selectedSubUser]);

  const [file, setFile] = useState({
    file: selectedSubUser?.profilePicture,
    url: selectedSubUser?.profilePicture,
  });

  if (!selectedSubUser && params?.id !== undefined) {
    return <Redirect to="/user" />;
  }

  const initialValues = {
    name: "",
    email: "",
    status: "ACTIVE",
    permissions: [],
    dailCode: "+91",
    contactNumber: "",
    profilePicture: selectedSubUser.profilePicture || "",
    newPassword: "",
  };

  const redirectBack = () => {
    history.push("/user");
  };
  const subUserSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter valid name "
      )
      .required("Please enter name"),
    email: Yup.string()
      .trim()
      .matches(regexValidation.EMAIL_REGEX, "Invalid email")
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter valid email "
      )
      .required("Please enter email"),
    status: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Status is required"
      )
      .required("Status is required"),
    contactNumber: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Mobile Number is required"
      )
      .matches(
        regexValidation.MOBILE_NUMBER_REGEX,
        "Mobile Number is not valid"
      )
      .required("Mobile number is required"),
    // dailCode: Yup.string()
    //   .trim()
    //   .notOneOf(
    //     ["null", "NULL", "Null", "Undefined", "undefined"],
    //     "Dail code is required"
    //   )
    //   .required("Dail code is required"),
    newPassword: Yup.string().trim(),
  });
  const status = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];
  const goback = () => {
    history.goBack();
  };

  const uploadImage = (e) => {
    setFile({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const AllPermissions = () => {
    setCheckValue(!checkValue);
    let ids = [];
    if (!checkValue) {
      let allAccess = "*.*";
      ids.push(allAccess);
      RolePermissions.map((item) => {
        ids.push(item.key + ".*");
        if (item.child) {
          item.child.map((c) => ids.push(item.key + "." + c.key));
        }
      });
    }
    setCheckIdsarray(ids);
  };

  const parentPermission = (e) => {
    const { value, checked } = e.target;
    let key = value.split(".");
    if (checked) {
      let ids = [value];
      if (key[1] == "*") {
        let item = RolePermissions.find((el) => el.key == key[0]);
        if (item && item.child) {
          item.child.map((c) => ids.push(item.key + "." + c.key));
        }
      }
      setCheckIdsarray([...checkIdsarray, ...ids]);
    } else {
      if (key[1] == "*") {
        setCheckIdsarray((current) =>
          current.filter((item) => item.indexOf(key[0] + ".") === -1)
        );
      } else {
        setCheckIdsarray((current) => current.filter((item) => item !== value));
      }
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={
        selectedSubUser && params?.id !== undefined
          ? selectedSubUser
          : initialValues
      }
      validationSchema={subUserSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        let formData = new FormData();
        if (file && file.file) {
          formData.append("profilePicture", file.file);
        }
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("dailCode", values.dailCode ? values.dailCode : "+91");
        formData.append(
          "contactNumber",
          values.contactNumber ? values.contactNumber : ""
        );
        formData.append("status", values.status);
        formData.append(
          "newPassword",
          values.newPassword ? values.newPassword : ""
        );
        formData.append("permissions", JSON.stringify(checkIdsarray));
        if (selectedSubUser && params?.id !== undefined) {
          dispatch(
            updateUser(
              formData,
              params.id,
              redirectBack,
              setSubmitting,
              resetForm
            )
          );
        } else {
          dispatch(userAsync(formData, redirectBack));
        }
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
      }) => (
        <Row className="justify-content-center">
          <Col lg={8}>
            <Card>
              <CardHeader
                title={
                  <div className="form-group m-0 d-flex align-items-center">
                    <button
                      className="btn btn-link px-0"
                      onClick={() => goback()}
                    >
                      <SVG
                        className="h-50 align-self-center"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-left.svg"
                        )}
                      />
                      Back
                    </button>
                    <span className="pl-2">
                      {selectedSubUser && params?.id !== undefined
                        ? "Edit User"
                        : "Add User"}
                    </span>
                  </div>
                }
              ></CardHeader>
              <CardBody>
                {isSubUserLoading && (
                  <div className="ml-3 basic-verification-loader text-center">
                    {<CircularProgress />}
                  </div>
                )}
                <Form>
                  <Row>
                    <div className="col-md-12">
                      <div className="col-md-12 text-center mb-4">
                        <div
                          className="pro_img border border-dark rounded-circle"
                          style={{
                            backgroundImage: `url(${
                              file.url
                                ? file.url
                                : params?.id && values && values.profilePicture
                                ? values.profilePicture
                                : "https://test-bucketstaging.s3.us-east-2.amazonaws.com/fulImage/1656417459984blank.png"
                            })`,
                          }}
                        >
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={uploadImage}
                          />
                          <div className="edt_img_btn">
                            <span className="material-icons">edit</span>EDIT
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <div className="form-group">
                    <label className="pho_14_500">Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      autoComplete="off"
                      name="name"
                      className={
                        errors.name && touched.name
                          ? "form-control re_inputRouded is-invalid"
                          : "form-control re_inputRouded"
                      }
                      onChange={handleChange}
                      defaultValue={values.name}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.name}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      placeholder="Enter Email"
                      autoComplete="off"
                      type="email"
                      name="email"
                      className={
                        errors.email && touched.email
                          ? "form-control re_inputRouded is-invalid"
                          : "form-control re_inputRouded"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.email}
                    />
                    {touched.email && errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.email}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="pho_14_500">Mobile Number</label>
                    <input
                      placeholder="Enter contactNumber"
                      autoComplete="off"
                      type="text"
                      name="contactNumber"
                      className={
                        errors.contactNumber && touched.contactNumber
                          ? "form-control re_inputRouded is-invalid"
                          : "form-control re_inputRouded"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.contactNumber}
                    />
                    {touched.contactNumber && errors.contactNumber ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {errors.contactNumber}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {!params?.id ? (
                    <div className="form-group">
                      <label className="form-label">New Password</label>
                      <input
                        placeholder="Enter password for set new password"
                        type="text"
                        style={{ marginTop: "7px" }}
                        name="newPassword"
                        className={
                          errors.name && touched.name
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.newPassword}
                      />
                      {touched.newPassword && errors.newPassword ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.newPassword}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {/* <div className="row"> */}
                  {/* <div className="col-lg-2">
                        <div className="form-group">
                          <label className="form-label">Mobile No</label>

                          <Select classNamePrefix="react-select" name="dailCode" placeholder="Select Dail Code" className={`form-control border-0 p-0 `}
                            options={callingCode}
                            value={[{ value: values.dailCode, label: values.dailCode }]}
                            onChange={(e) => { values.dailCode = e.value }}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#DCF4E4",
                                primary75: "#DCF4E4",
                                primary50: "#DCF4E4",
                                primary: "#50C878",
                              },
                            })}
                          />
                          {touched.dailCode && errors.dailCode ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.dailCode}</div>
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                  {/* <div className="col-lg-10 ">
                      <div className="form-group">
                        <label className="form-label"></label>
                        <input
                          placeholder="Mobile Number"
                          type="text"
                          className={`form-control`}
                          style={{ marginTop: "7px" }}
                          name="contactNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values.contactNumber}
                        />
                        {touched.contactNumber && errors.contactNumber ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.contactNumber}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                  {/* </div> */}

                  <div className="form-group">
                    <label className="pho_14_500">Status</label>
                    <Select
                      name="status"
                      placeholder="Select Status"
                      className={`form-control border-0 p-0 `}
                      classNamePrefix="phoSelect"
                      options={status}
                      onChange={(e) => {
                        values.status = e.value;
                      }}
                      defaultValue={[
                        {
                          value: values.status,
                          label:
                            values.status == "ACTIVE" ? "ACTIVE" : "INACTIVE",
                        },
                      ]}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#DCF4E4",
                          primary75: "#DCF4E4",
                          primary50: "#DCF4E4",
                          primary: "#50C878",
                        },
                      })}
                    />
                    {touched.status && errors.status ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.status}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                    <div className="mr-5 mb-1 mt-2">
                      <Link className="btn btn-outline-blue" to="/user">
                        Close
                      </Link>
                    </div>
                    <div className="mb-1 mt-2">
                      <Button
                        variant="blue"
                        className="px-5 defpddng spinnerBtn"
                        size="lg"
                        type="submit"
                        disabled={isSubUserLoading}
                      >
                        {selectedSubUser && params?.id !== undefined
                          ? "Update"
                          : "Submit"}
                        {/* {isSubUserLoading && (
                            <div className="ml-3 basic-verification-loader text-center">
                              {<CircularProgress />}
                            </div>
                          )} */}
                      </Button>
                    </div>
                  </div>
                  <div className="text-center"></div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardHeader
                title={
                  <div className="form-group m-0 d-flex align-items-center">
                    <span className="pl-2">Permissions</span>
                  </div>
                }
              ></CardHeader>
              <CardBody>
                <div className="form-group">
                  <div className="d-flex">
                    <div className="mr-3">
                      <Checkbox
                        type="checkbox"
                        name="checkAll"
                        id="checkAll"
                        handleClick={AllPermissions}
                        isChecked={checkIdsarray.includes("*.*")}
                      />
                    </div>
                    <span>All Permissions</span>
                  </div>
                  <ul>
                    {RolePermissions && RolePermissions.length ? (
                      RolePermissions.map((rolePermission, index) => (
                        <li style={listStyleClass} key={"main_p_" + index}>
                          <div className="d-flex p-1">
                            <div className="mr-2">
                              <Checkbox
                                type="checkbox"
                                name={rolePermission.key}
                                id={rolePermission.key}
                                key={"chk" + rolePermission.key}
                                handleClick={parentPermission}
                                value={rolePermission.key + ".*"}
                                isChecked={checkIdsarray.includes(
                                  rolePermission.key + ".*"
                                )}
                              />
                            </div>

                            {rolePermission.label}
                          </div>
                          <ul>
                            {rolePermission?.child.map((childRole, index) => (
                              <li
                                style={listStyleClass}
                                key={"child_p_" + index}
                              >
                                <div className="d-flex p-1">
                                  <div className="mr-2">
                                    <Checkbox
                                      type="checkbox"
                                      name="checkAll"
                                      id={childRole.key}
                                      handleClick={parentPermission}
                                      value={
                                        rolePermission.key + "." + childRole.key
                                      }
                                      // defaultValue={checkIdsarray}
                                      isChecked={checkIdsarray.includes(
                                        rolePermission.key + "." + childRole.key
                                      )}
                                    />
                                  </div>
                                  {childRole.label}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))
                    ) : (
                      <div>No Record Found</div>
                    )}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Formik>
  );
};
export default UpdateUser;
