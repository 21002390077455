export const BlogMap = {
  CREATE_BLOG_START: "CREATE_BLOG_START",
  CREATE_BLOG_SUCCESS: "CREATE_BLOG_SUCCESS",
  CREATE_BLOG_ERROR: "CREATE_BLOG_ERROR",
  GET_ALL_BLOG_START: "GET_ALL_BLOG_START",
  GET_ALL_BLOG_SUCCESS: "GET_ALL_BLOG_SUCCESS",
  GET_ALL_BLOG_ERROR: "GET_ALL_BLOG_ERROR",
  UPDATE_BLOG_START: "UPDATE_BLOG_START",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
  UPDATE_BLOG_ERROR: "UPDATE_BLOG_ERROR",
  DELETE_BLOG_START: "DELETE_BLOG_START",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_ERROR: "DELETE_BLOG_ERROR",
  GET_BLOG_DETAIL_START: "GET_BLOG_DETAIL_START",
  GET_BLOG_DETAIL_SUCCESS: "GET_BLOG_DETAIL_SUCCESS",
  GET_BLOG_DETAIL_ERROR: "GET_BLOG_DETAIL_ERROR",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  SEARCH_CHANGE_STATUS: "SEARCH_CHANGE_STATUS",
  REFRESH_BLOG_LIST: "REFRESH_BLOG_LIST",
  SET_BLOG_BATCH_NUMBER: "SET_BLOG_BATCH_NUMBER",
};

export const HfdiBlogActions = {
  getAllBlog: (data) => ({ type: BlogMap.GET_ALL_BLOG_START, payload: data }),
  getAllBlogSuccess: (data) => ({
    type: BlogMap.GET_ALL_BLOG_SUCCESS,
    payload: data,
  }),
  getAllBlogError: () => ({ type: BlogMap.GET_ALL_BLOG_ERROR }),

  createBlogStart: () => ({ type: BlogMap.CREATE_BLOG_START }),
  createBlogSuccess: (data) => ({
    type: BlogMap.CREATE_BLOG_SUCCESS,
    payload: data,
  }),
  createBlogError: (errors) => ({
    type: BlogMap.CREATE_BLOG_ERROR,
    payload: { errors },
  }),

  updateBlogStart: () => ({ type: BlogMap.UPDATE_BLOG_START }),
  updateBlogSuccess: (data) => ({
    type: BlogMap.UPDATE_BLOG_SUCCESS,
    payload: data,
  }),
  updateBlogError: (errors) => ({
    type: BlogMap.UPDATE_BLOG_ERROR,
    payload: { errors },
  }),

  deleteBlogStart: () => ({ type: BlogMap.DELETE_BLOG_START }),
  deleteBlogSuccess: (data) => ({
    type: BlogMap.DELETE_BLOG_SUCCESS,
    payload: data,
  }),
  deleteBlogError: (errors) => ({
    type: BlogMap.DELETE_BLOG_ERROR,
    payload: { errors },
  }),

  getBlogDetail: (data) => ({
    type: BlogMap.GET_BLOG_DETAIL_START,
    payload: data,
  }),
  getBlogDetailSuccess: (data) => ({
    type: BlogMap.GET_BLOG_DETAIL_SUCCESS,
    payload: data,
  }),
  getBlogDetailError: () => ({ type: BlogMap.GET_BLOG_DETAIL_ERROR }),

  searchTextChange: (data) => ({
    type: BlogMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  searchChangeStatus: (data) => ({
    type: BlogMap.SEARCH_CHANGE_STATUS,
    payload: data,
  }),
  refreshBlogList: () => ({ type: BlogMap.REFRESH_BLOG_LIST }),
  setBlogBatchNumber: (data) => ({
    type: BlogMap.SET_BLOG_BATCH_NUMBER,
    payload: data,
  }),
};
