import axios from "axios";
import { EnvironmentActions } from "./getEnvironmentAction";

export const getEnvironmentsAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(EnvironmentActions.getEnvironments());
      let { data } = await axios({
        url: process.env.REACT_APP_ENVIRONMENT_URL,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.code === 200) {
        return dispatch(EnvironmentActions.getEnvironmentsSuccess(data.data));
      }
      dispatch(EnvironmentActions.getEnvironmentsError());
    } catch (error) {
      dispatch(EnvironmentActions.getEnvironmentsError());
    }
  };
};
export const getWebsiteListAsync = () => {
  return async (dispatch) => {
    const webSiteList = [
      {
        value: "all",
        label: "all",
      },
      {
        value: "webcluesinfotech",
        label: "webcluesinfotech",
      },
      {
        value: "wdcstechnology",
        label: "wdcstechnology",
      },
      {
        value: "wdcsdubai",
        label: "wdcsdubai",
      },
      {
        value: "hireaideveloper",
        label: "hireaideveloper",
      },
      {
        value: "hirefullstackdeveloperindia",
        label: "hirefullstackdeveloperindia",
      },
    ];
    return dispatch(
      EnvironmentActions.getEnvironmentsWebsiteListSuccess(webSiteList)
    );
  };
};
